/*eslint-disable */
import _ from 'lodash';

export function getSearchFilterObj(state) {
    return state.searchFilterObj;
}

export function getIsPlayNotificationSound(state) {
    return state.isPlayNotificationSound;
}

export function getAllRFQs(state) {
    return state.items;
}

export function getQPNumbers (state) {
    let result = [];
    _.forEach(state.items, (itm) => {
        if (!_.isEmpty(itm.QPNumber)) {
            result.push(itm.QPNumber)
        }
    })

    return _.uniq(_.orderBy(result, (nbr) => {
        return _.trim(_.toLower(nbr));
    }));
}

export function getToken(state) {
    return state.token;
}

export function getReceivedRFQs(state) {
    let rfqs = _.filter(state.items, function (i) {
        return i.status === 'Received'
    })
    return _.orderBy(rfqs, ['dateDue'], ['desc'])
    // let ids = state.received;
    // let rfqs = _.filter(state.items, function (i) {
    //     return _.indexOf(ids, i.id) >= 0;
    // });
    // return rfqs;
}

export function getSubmittedRFQs(state) {
    let rfqs = _.filter(state.items, function (i) {
        return i.status === 'Submitted'
    })
    return _.orderBy(rfqs, ['dateDue'], ['desc'])
}

export function getPricedRFQs(state) {
    let rfqs = _.filter(state.items, function (i) {
        return i.status === 'Priced'
    })
    return _.orderBy(rfqs, ['dateDue'], ['desc'])
}

export function getOrderedRFQs(state) {
    let rfqs = _.filter(state.items, function (i) {
        return i.status === 'Ordered'
    })
    return _.orderBy(rfqs, ['dateDue'], ['desc'])
}

export function getCancelledRFQs(state) {
    let rfqs = _.filter(state.items, function (i) {
        return i.status === 'Cancelled'
    })
    return _.orderBy(rfqs, ['dateDue'], ['desc'])
}

export function getFilter(state) {
    return state.filter;
}

export function getCurrentRFQ(state) {
    return state.currentRfq;
}

export function countReceivedRFQs(state,getters) {
    return getters.getReceivedRFQs.length
}

export function countSubmittedRFQs(state,getters) {
    return getters.getSubmittedRFQs.length
}

export function countPricedRFQs(state,getters) {
    return getters.getPricedRFQs.length
}

export function getAllRFQsCurrentPage(state) {
    return state.allRFQsCurrentPage;
}

export function getReceivedRFQsCurrentPage(state) {
    return state.receivedRFQsCurrentPage;
}

export function getSubmittedRFQsCurrentPage(state) {
    return state.submittedRFQsCurrentPage;
}

export function getPricedRFQsCurrentPage(state) {
    return state.pricedRFQsCurrentPage;
}

export function getOrderedRFQsCurrentPage(state) {
    return state.orderedRFQsCurrentPage;
}

export function getCancelledRFQsCurrentPage(state) {
    return state.cancelledRFQsCurrentPage;
}

export function getPerPage (state) {
    return state.perPage;
}

export function getPerPageAll (state) {
    return state.perPageAll;
}
